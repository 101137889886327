import React, { useEffect, useContext, Fragment } from 'react';
import CustomLoaderPreview from '../../components/CustomLoaderPreview';
import ShowLoadingContext from '../../context/ShowLoadingContext';
import './CustomLoaderEditor.css';
import CustomLoaderService from '../../services/CustomLoader';
import { CustomLoaderContext } from '../../context/CustomLoaderContext';
import Loading from '../../components/loading';


function CustomLoaderEditor() {

    const { showLoading, setShowLoading } = useContext(ShowLoadingContext)
    const campaignId = window.location.pathname.split('/')[3];
  
    const {
        setIconColor, 
        setTextColor, 
        setHeaderFont,
        setLoadingBarColor, 
        setBackgroundColor,
        setBackgroundGradiantColor,
        setIsGridActive,
        setIsGradiantActive,
        setLogoURL,
        setCenterLogoLocal,
        setBackgroundLocal,
        setResetBackground,
        setResetCenterLogo,
        setDefaultLogo,
        setFooterLogoThemeColor,
        setResetLogo } = useContext(CustomLoaderContext);

    useEffect( () => {
        getCustomLoaderConfig();
    }, []);

    async function getCustomLoaderConfig() {
        setShowLoading(true);
        (CustomLoaderService.getCustomLoaderConfig(campaignId))
        .then(response=> response.json())
        .then(data => {
            setData(data);
        });
    }

    const setData = (jsonData) => {
        setIconColor(jsonData.iconColor == "null" ? "#3434E0" : jsonData.iconColor);
        setTextColor(jsonData.textColor);
        setLoadingBarColor(jsonData.loadingBarColor);
        setBackgroundColor(jsonData.backgroundColor);
        setBackgroundGradiantColor(jsonData.backgroundGradiantColor);
        setIsGridActive(jsonData.isGrid == 'true' ? true : false );
        setIsGradiantActive(jsonData.isGradiant == 'true' ? true : false );
        setLogoURL(jsonData.logoURL);
        setCenterLogoLocal(jsonData.centerLogoURL || ""); // to be added
        setBackgroundLocal(jsonData.backgroundURL);
        setResetLogo(false);
        setResetBackground(false);
        setResetCenterLogo(false);
        setShowLoading(false);
        setFooterLogoThemeColor(jsonData.footerThemeColor || 'black');
        setDefaultLogo(jsonData.hasDefaultLogo == "true" || jsonData.hasDefaultLogo == true ? true : false)
        setHeaderFont(jsonData.headerFont || 'CoaFont');
    }
    
    return (
        <Fragment>
            {showLoading && 
                <Loading/>
            }
            <div className="preview-container">
                <CustomLoaderPreview className="preview"/>
            </div>
        </Fragment>
    );
}

export default CustomLoaderEditor;