import React, {useContext, useEffect, useRef, useState} from 'react';
import {AutoComplete, Button, ControlLabel, Form, FormGroup, Icon, InputGroup, Modal, SelectPicker} from "rsuite";
import {useTranslate} from "react-polyglot";


const VideoPlayerDialogComponent = (props) => {
    const t = useTranslate();

    // const {openQRDialog, setOpenQRDialog, dialogData} = useContext(QRDialogContext);
    // const {url, published, shortUrl} = dialogData;
    // const [loading, setLoading] = useState(true);
    const divElement = useRef(null);

    const modalSize = window.innerHeight > 627 ? 'lg' : 'md';

    return (
        <div>
            <Modal size={modalSize} show={props.openDialog} onHide={props.handleCloseModal}>
                <Modal.Header>
                    <Modal.Title>{props.video?.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{overflow: 'hidden', maxHeight: '100%'}}>
                    <div style={{
                        aspectRatio: '16/9',
                        maxWidth: '100%',
                        height: '100%',
                        textAlign: 'center',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        position: 'relative',
                    }}>
                        { props.video &&
                            <iframe
                                allowFullScreen
                                allow='autoplay'
                                title={props.video.title}
                                src={props.video.url}
                                style={{
                                    border: 'none',
                                    position: 'absolute',
                                    top: 0,
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    height: "100%",
                                    width: '100%',
                                }}
                            />
                        }
                        {/* {loading ? null : ''} */}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default VideoPlayerDialogComponent;