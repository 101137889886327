import React, {Fragment, useState, useEffect} from 'react';
import {Authenticator, Greetings, SignUp, SignIn, ForgotPassword, RequireNewPassword} from 'aws-amplify-react';
import {Portal} from '@rmwc/base';

import Router from '../router';
import awsconfig from '../../aws-exports';
import amplifyTheme from './amplifyTheme';
import setCookie from '../../utils/cookie';
import {AppProvider} from './AppContext';
import {GlobalStyle} from '../app/globalStyles';
import {getUserGroups} from '../../utils/user';
import userService from "../../services/User";
import {default as CustomSignUp} from '../signUp';
import {default as CustomSignIn} from '../signIn';
import CustomForgot from '../forgotPass/CustomForgot';
import CustomRequireNewPass from '../forgotPass/CustomRequireNewPass';

import './App.css';
import 'rsuite/dist/styles/rsuite-default.css';
import UserProvider from "../../context/UserContext";
import MigrateUserDialogProvider from "../../context/MigrateExperienceUserDialogContext";
import QRDialogProvider from "../../context/QRDialogContext";
import ChangeTargetDialogProvider from "../../context/ChangeTargetDialogContext";
import ChangeNameDialogProvider from "../../context/ChangeNameDialogContext";
import { ShowLoadingProvider } from '../../context/ShowLoadingContext'
import OverlaysProvider from '../../context/OverlaysContext';
import CustomLoaderProvider from '../../context/CustomLoaderContext';
import { isMobile } from '../../utils/utils';
import WarningScreenMobile from '../../pages/WarningScreenMobile/WarningScreenMobile';

function App() {
    const [userGroups, setUserGroups] = useState([]);
    const [userData, setUserData] = useState({});

    useEffect(() => {
        if (!isMobile()) {
            setCookie();
            getUserGroups().then(res => setUserGroups(res));
            getUserData();
        }
    }, []);

    const getUserData = async () => {
        try {
            let user = await userService.getProfile();
            setUserData(user);
        } catch (e) {
            console.log(e);
        }
    };

    return (<>
    {
        isMobile() ? 
         <WarningScreenMobile/>
        :
        <ShowLoadingProvider>
            <UserProvider>
                <Fragment>
                    <GlobalStyle/>
                    <AppProvider value={{userGroups, userData}}>
                        <OverlaysProvider>
                        <CustomLoaderProvider>
                            <MigrateUserDialogProvider>
                                <QRDialogProvider>
                                    <ChangeTargetDialogProvider>
                                        <ChangeNameDialogProvider>
                                            <Authenticator
                                                theme={amplifyTheme}
                                                amplifyConfig={awsconfig}
                                                hide={[Greetings, SignUp, SignIn, ForgotPassword, RequireNewPassword]}
                                            >
                                                    <CustomSignIn override={'SignIn'}/>
                                                    <CustomSignUp override={'SignUp'}/>
                                                    <CustomForgot override={'ForgotPassword'}/>
                                                    <CustomRequireNewPass override={'RequireNewPassword'}/>
                                                    <Router/>
                                                    <Portal/>
                                                
                                            </Authenticator>
                                        </ChangeNameDialogProvider>
                                    </ChangeTargetDialogProvider>
                                </QRDialogProvider>
                            </MigrateUserDialogProvider>
                        </CustomLoaderProvider>
                        </OverlaysProvider>
                    </AppProvider>
                </Fragment>

            </UserProvider>
        </ShowLoadingProvider>
    }
    </>);
}

export default App;
