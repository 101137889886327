import React, {useEffect, useState, useContext, Fragment} from 'react';

import AppContext from '../../components/app/AppContext';
import Loading from '../../components/loading';
import ShowLoadingContext from '../../context/ShowLoadingContext';
import { Card, CardActions, CardMedia, CardPrimaryAction } from 'rmwc';
import { CardWrapper } from '../../components/videoTutorialCard/VideoTutorialCardStyle';
import VideoPlayerDialogComponent from '../../components/actionsDialog/VideoPlayerDialogComponent';
import Slider from 'react-slick';
import PrevArrowComponent from '../../components/layout/PrevArrowComponent';
import NextArrowComponent from '../../components/layout/NextArrowComponent';


const VIDEO_TUTORIALS_JSON_URL = `${process.env.REACT_APP_VIDEOTUTORIALS_BASE_URL ? process.env.REACT_APP_VIDEOTUTORIALS_BASE_URL : ''}/data/videotutorials.json`;

function VideoTutorials() {
    const context = useContext(AppContext);
    const [tutorials, setTutorials] = useState([])

    const [openDialog, setOpenDialog] = useState(false)
    const [selectedVideo, setSelectedVideo] = useState(null)

    const { 
        showLoading,
        setShowLoading,
    } = useContext(ShowLoadingContext)


    function handleClickVideo() {
        setSelectedVideo(this.video)
        setOpenDialog(true)
    }

    function handleCloseModal() {
        setOpenDialog(false)
        setSelectedVideo(null)
    }

    const fetchVideos = async () => {
        setShowLoading(true);
        try {
          const response = await fetch(VIDEO_TUTORIALS_JSON_URL);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const result = await response.json();
          setTutorials(result);
        } catch (err) {
            //   setError(err.message);
        } finally {
          setShowLoading(false);
        }
      };

    useEffect(() => {
        context.setPage({
            name: 'Videos',
            hideFilters: true,
        });
        fetchVideos();
    }, []);


    const settings = {
        className: "slider variable-width",
        dots: false,
        infinite: false,
        variableWidth: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 0,
        prevArrow: <PrevArrowComponent/>,
        nextArrow: <NextArrowComponent/>,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 1650,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 870,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <Fragment>
            <VideoPlayerDialogComponent
                video={selectedVideo} 
                openDialog={openDialog}
                handleCloseModal={handleCloseModal}
            />
            <div className={'list'}>
            {
                tutorials.map((e, i) => 
                    <div key={`key-module-${i}`} className={'list-container'} style={{width: '100%'}}>
                        <div className={'list-container-header'}>
                            <span className="title titleMargin">{e.name}</span>
                        </div>
                        <Slider data-key={`tutorial-module-${i}`} {...settings}>
                        {
                            e.videos.map((v,j) => 
                                <CardWrapper key={`card-video-m${i}-${j}`}>
                                    <Card className="campaign-exp-card experience-card" style={{width: '235px', height: '205px'}}>
                                        <CardPrimaryAction>
                                            <CardMedia
                                                sixteenByNine
                                                style={{
                                                    backgroundImage: `url(${v.thumbnail})`
                                                }}
                                                onClick={handleClickVideo.bind({video: v})}
                                            />
                                        </CardPrimaryAction>
                                        <CardActions>
                                            <div className={'card-experience description-block'}>
                                                <div style={{marginLeft: '16px', marginRight: '16px', marginTop: '8px'}}>
                                                    <div className="truncateTitle">
                                                        <span className={'card-experience title'}>{v.title}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardActions>
                                    </Card>                    
                                </CardWrapper>
                            )
                        }
                        </Slider>
                    </div>
                )
            }
            </div>
            {showLoading && 
                <Loading/>
                }
        </Fragment>
    );
}

export default VideoTutorials;
