import React, {useContext, useEffect} from 'react';
import {Breadcrumb} from "rsuite";
import {UserContext} from "../../context/UserContext";
import NavLink from "../../components/menu/navLink";
import {navigate} from "@reach/router";
import {useTranslate} from "react-polyglot";

const HomeBread = () => {
    const {setNavLinks, userData, navLinks} = useContext(UserContext);
    const t = useTranslate();
    useEffect(() => {
        if (userData.id) {
            setNavLinks([{
                id: userData.id,
                name: userData.username,
                tab: 'user'
            }])
        } else {
            if (window.location.pathname.split('/')[1] === 'campaign') {
               /* navigate(`/`);
                setNavLinks([{
                    id: '',
                    name: t('pages.campaigns.project'),
                    tab: 'allprojects'
                }])*/
            } else if (window.location.pathname.split('/')[1] === 'video-tutorials') {
                setNavLinks([{
                    id: '',
                    name: t('pages.videoTutorials.project'),
                    tab: ''
                }]) 
            } else {
                setNavLinks([{
                    id: '',
                    name: t('pages.campaigns.project'),
                    tab: 'allprojects'
                }])
            }
        }

    }, [userData]);

    const goToLink = (e) => {
        e.preventDefault();
        let parsedTab = JSON.parse(e.target.dataset["key"]);
        if (parsedTab.tab === 'project') {
            let oTabFound = navLinks.find(e => e.tab === parsedTab.tab);
            if (!oTabFound)
                navigate(`/campaign/${parsedTab.id}`);
        }
        if (parsedTab.tab === 'user'){
            window.location.pathname = `/user/${parsedTab.id}`;
        }
        if (parsedTab.tab === 'allprojects') {
            let spliced = navLinks.splice(0, 1);
            setNavLinks(spliced);

            let oTabFound = navLinks.find(e => e.tab === parsedTab.tab);
            if (!oTabFound)
                navigate(`/`);
        }
    };

    return (
        <Breadcrumb separator={'>'} className={'homeBreadcrumbMargin'}>
            {navLinks.map((e) => (<NavLink data-key={JSON.stringify(e)}
                                           key={new Date().valueOf().toString(36) + Math.random().toString(36).substr(2)}
                                           to={e.name} onClick={goToLink}>{e.name}</NavLink>))}
        </Breadcrumb>
    );
};

export default HomeBread;