export const AVAILABLE_FONTS = [
    {
        name: 'CoaFont',
        bold: {
            fontFamily: 'coaFontBold',
            fontWeight: 'bold'
        },
        normal: {
            fontFamily: 'coaFontLight',
            fontWeight: 'normal'
        },
        urls: [
            'https://cdn-studio.camonapp.com/_loader/brand/base/css/CarbonOT-Bold.ttf',
            'https://cdn-studio.camonapp.com/_loader/brand/base/css/CarbonOT-Regular.ttf'
        ]
    },
    {
        name: 'Arimo',
        bold: {
            fontFamily: 'Arimo',
            fontWeight: 'bold'
        },
        normal: {
            fontFamily: 'Arimo', 
            fontWeight: 'normal'
        },
        urls: [
            'https://cdn-studio.camonapp.com/_loader/brand/base/css/Arimo-VariableFont_wght.ttf'
        ]
    },
    {
        name: 'Helvetica Neue LT Std',
        bold: {
            fontFamily: 'Helvetica Neue LT Std',
            fontWeight: 'bold'
        },
        normal: {
            fontFamily: 'Helvetica Neue LT Std',
            fontWeight: 'normal'
        },
        urls: [
            'https://cdn-studio.camonapp.com/_loader/brand/base/css/HelveticaNeueLTStd-Roman.otf'
        ]
    },
    {
        name: 'Barlow',
        bold: {
            fontFamily: 'Barlow',
            fontWeight: 'bold'
        },
        normal: {
            fontFamily: 'Barlow',
            fontWeight: 'normal'
        },
        urls: [
            'https://cdn-studio.camonapp.com/_loader/brand/base/css/Barlow-Medium.ttf'
        ]
    },
    {
        name: 'Milo Pro',
        bold: {
            fontFamily: 'Milo Pro',
            fontWeight: 'bold'
        },
        normal: {
            fontFamily: 'Milo Pro',
            fontWeight: 'normal'
        },
        urls: [
            'https://cdn-studio.camonapp.com/_loader/brand/base/css/Milo Pro-Medium.otf'
        ]
    },
    {
        name: 'Nunito',
        bold: {
            fontFamily: 'Nunito-Bold',
            fontWeight: 'bold'
        },
        normal: {
            fontFamily: 'Nunito-Light',
            fontWeight: 'normal'
        },
        urls: [
            'https://cdn-studio.camonapp.com/_loader/brand/base/css/Nunito-Bold.ttf',
            'https://cdn-studio.camonapp.com/_loader/brand/base/css/Nunito-Light.ttf'
        ]
    },
    {
        name: 'D-DIN',
        bold: {
            fontFamily: 'D-DIN',
            fontWeight: 'bold'
        },
        normal: {
            fontFamily: 'D-DIN',
            fontWeight: 'normal'
        },
        urls: [
            'https://cdn-studio.camonapp.com/_loader/brand/base/css/D-DIN-Bold.otf'
        ]
    },
    {
        name: 'Proxima Nova',
        bold: {
            fontFamily: 'Proxima Nova',
            fontWeight: 'bold'
        },
        normal: {
            fontFamily: 'Proxima Nova',
            fontWeight: 'normal'
        },
        urls: [
            'https://cdn-studio.camonapp.com/_loader/brand/base/css/ProximaNova-Bold.ttf',
            'https://cdn-studio.camonapp.com/_loader/brand/base/css/ProximaNova-Regular.ttf'
        ]
    },
    {
        name: 'Futura Std',
        bold: {
            fontFamily: 'Futura Std',
            fontWeight: 'bold'
        },
        normal: {
            fontFamily: 'Futura Std',
            fontWeight: 'normal'
        },
        urls: [
            'https://cdn-studio.camonapp.com/_loader/brand/base/css/FuturaStd-Book.otf'
        ]
    },
    {
        name: 'GothamRnd',
        bold: {
            fontFamily: 'GothamRnd',
            fontWeight: 'bold'
        },
        normal: {
            fontFamily: 'GothamRnd',
            fontWeight: 'normal'
        },
        urls: [
            'https://cdn-studio.camonapp.com/_loader/brand/base/css/GothamRnd-Bold.otf'
        ]
    },
];

export const getFontFamily = (fontName) => {
    const font = AVAILABLE_FONTS.find(font => font.name === fontName);
    return font ? font.normal.fontFamily : 'coaFontLight';
}