export const getRandomRgba = () => {
    let o = Math.round, r = Math.random, s = 255;
    return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + r().toFixed(1) + ')';
};

export const hasSessionExpired = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const error = urlParams.get('error');
    return error === 'session_expired';
};

export const checkSessionExpired = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const experienceId = urlParams.get('experience');
    if (hasSessionExpired()) {
        window.location.replace(`${process.env.REACT_APP_EDITOR_DOMAIN}?id=${experienceId}`);
    }
};

export const isMobile = () => {
    const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(navigator.userAgent);
}