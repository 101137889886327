import React, {useState, useContext} from 'react';
import {navigate} from "@reach/router";
import {Badge} from '@rmwc/badge';
import {useTranslate} from 'react-polyglot';

import ElipsisMenu from '../../components/elipsisMenu';
import ProjectName from '../../components/actions/ProjectName';
import AppContext from '../../components/app/AppContext';
import CampaignService from '../../services/Campaign';
import Modal from '../../components/modal/modal';
import Confirm from '../../components/actions/Confirm';
import CheckPermission from '../../components/checkPermision/checkPermision';
import {userGroupsObject} from '../../utils/userGroups';
import {UserContext} from "../../context/UserContext";
import Slider from "react-slick";

import ExperienceService from '../../services/Experience';
import CustomMonoriel from '../../services/CustomMonoriel'
import './campaignListItem.scss';
import '@rmwc/badge/badge.css';
import PrevArrowComponent from "../../components/layout/PrevArrowComponent";
import NextArrowComponent from "../../components/layout/NextArrowComponent";
import { CardHomeStudio } from './CardHomeStudio';

function CampaingListItem(props) {
    const context = useContext(AppContext);
    const t = useTranslate();
    const [expand, setExpand] = useState(true);
    const [edit, setEdit] = useState(false);
    const [cmpDataObj, setCmpDataObj] = useState({});
    const {userData, navLinks, setNavLinks} = useContext(UserContext);

    const hasUserURL = () => {
        let paths = window.location.pathname.split('/');
        let userId = paths.length > 1 ? paths[2] : '';
        return userId
    };

    function goToCampaign() {
        setNavLinks([...navLinks, ...[{
            id: props.campaign.id,
            name: props.campaign.name,
            tab: 'project'
        }]]);
        if (hasUserURL()) {
            window.location.pathname += `/project/${props.campaign.id}`
        } else {
            navigate(`/campaign/${props.campaign.id}`);
        }
    }

    async function editProject(value) {
        try {
            const res = await CampaignService.editCampaign({name: value}, this);
            props.reload(res);
        } catch (error) {

        }
    }

    function removeProject(id) {
        CampaignService.removeCampaign(id)
            .then(() => context.page.refetch())
            .catch(err => console.log(err));
    }

    function handleChangeProjectName() {
        const actionsDialogData = {
            title: t('pages.campaigns.modal.edit.title'),
            componentData: props.campaign.name,
            component: ProjectName,
            componentKey: props.campaign.id,
            action: editProject.bind(props.campaign.id),
        };
        setCmpDataObj({...actionsDialogData});
        setEdit(true);
    }
    async function deleteExperience(id,publicAlias) {
        try {
            let response = await ExperienceService.delete(id);
            response = await CustomMonoriel.deleteCletusAlias(publicAlias);
            return response;

        } catch (error) {
            throw new Error(error);
        }
    }
    function removeProjectAndExperiences (id,experiences){
        experiences.map((e) => {
            if(e.experienceTypeId == 5){
                return deleteExperience(e.id,e.link.public_alias)
            }
        })
        removeProject(id) 
    }
    function handleDelete() {
        const actionsDialogData = {
            title: t('pages.campaigns.modal.delete.title'),
            componentData: {msg: t('pages.campaigns.modal.delete.msg')},
            component: Confirm,
            componentKey: props.campaign.id,
            action: () => removeProjectAndExperiences(props.campaign.id, props.campaign.experiences),
        };
        setCmpDataObj({...actionsDialogData})
        setEdit(true);
    }

    function handleEditCustomLoader() {
        navigate('/customLoader/campaign/' + props.campaign.id);
    }

    const isFreelance = () => context.userGroups.includes(userGroupsObject.FreelanceUsers);

    function goToReports() {
        navigate(`${process.env.REACT_APP_REPORTS_V2}/goto?company_id=${props.campaign.experiences[0].companyId}&campaign_id=${props.campaign.id}`);
    }

    const canSeeReport = () => {
        if (context.userGroups.includes(userGroupsObject.CompanyUsers)) {
            return context.userData.company ? context.userData.company.canSeeReport : false;
        }
        return true;
    };

    const chunkArray = (myArray, chunk_size) => {
        let results = [];
        while (myArray.length) {
            results.push(myArray.splice(0, chunk_size));
        }
        return results;
    };

    const isAdmin = () => {
        if (context.userGroups.find(e => e === 'CamonappAdminUsers') !== undefined) {
            return 'Admin'
        }
    };
    const isEditor = () => {
        if (context.userGroups.find(e => e === 'CamonappUsers') !== undefined) {
            return 'Editor'
        }
    };
    
    const settings = {
        className: "slider variable-width",
        dots: false,
        infinite: false,
        variableWidth: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 0,
        prevArrow: <PrevArrowComponent/>,
        nextArrow: <NextArrowComponent/>,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 1650,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 870,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div
            open={expand}
            className={'list-container'}
        >
            <div className={'list-container-header'}>
                <span className="title titleMargin">{props.campaign.name}</span>
                <span className="action" style={{marginLeft: '16px'}}
                      onClick={goToCampaign}>{t('pages.campaigns.openProject')}</span>
                <div className={'header-actions'}>
                    <div className={'actions'}>
                        <span className={'quantity'}>
                            {props.campaign.experiences && props.campaign.experiences.length ? props.campaign.experiences.length > 1 ? `${props.campaign.experiences.length} ${t('pages.campaigns.experiences')}` : `${props.campaign.experiences.length} ${t('pages.campaigns.experience')}` : 0}
                        </span>
                    </div>

                    <ElipsisMenu rippled>
                        <div className={'menu-item-dark'} onClick={handleChangeProjectName}>
                            <span>{t('pages.campaigns.actions.changeName')}</span>
                        </div>
                        {
                            isAdmin() ? <div className={'menu-item-dark'} onClick={() => {
                                props.openUserMigrationDialog();
                                props.setSelectedCampaign(props.campaign.id);
                            }}>
                                <span>{t('pages.campaigns.actions.migProject')}</span>
                            </div> : null
                        }
                        <div className={'menu-item-dark'} onClick={handleDelete}>
                            <span>{t('pages.campaigns.actions.delete')}</span>
                        </div>
                        <div className={'menu-item-dark'} onClick={handleEditCustomLoader}>
                            <span>{t('pages.campaigns.actions.editCustomLoader')}</span>
                        </div>
                        <CheckPermission
                            component={(
                                <div className={`menu-item-dark`}
                                     onClick={() => goToReports()}>
                                    <span>{t('pages.campaigns.actions.viewReport')}</span>
                                </div>
                            )}
                            groups={[
                                userGroupsObject.CamonappUsers,
                                userGroupsObject.CamonappAdminUsers,
                                userGroupsObject.CamonappSalesUsers,
                                userGroupsObject.CompanyUsers,
                                userGroupsObject.FreelanceUsers,
                                userGroupsObject.FreelancePaidUsers,
                            ]}
                        />
                    </ElipsisMenu>


                </div>
            </div>
            {props.campaign.experiences && props.campaign.experiences.length ? <Slider onEdge={(e) => {
                console.log(e)
            }} data-key={`${props.campaign.id}`} {...settings}>
                {props.campaign.experiences.map((experience, index) => {
                    return (
                        <CardHomeStudio key={index} experienceCardStyle={'margin-card'} experience={experience}/>
                    )
                })}
            </Slider> : null}
            {edit &&
            <Modal {...cmpDataObj} onClose={() => {
                setEdit(false)
            }}/>
            }

        </div>
    )
}

export default CampaingListItem;
