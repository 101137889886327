import React from 'react'
import './SausageButton.scss';

const SausageButton = ({outlined, disabled, invert ,label, onClick, fluid, type, size, icon, fsize, spacer, secondary,raised, marginLeft }) => {
    return (
        <button 
            type={type ? type:'button'}
            className= {`sausage-button 
                ${ size ? size:'medium'} 
                ${ fluid ? 'fluid':''} 
                ${ secondary ? 'secondary':''} 
                ${ raised ? 'raised':''} 
                ${ disabled ? 'disabled' : `${outlined && 'outlined'} ${invert && 'invert'}`}
            `}

            style={
                fsize && {fontSize: fsize},
                spacer ? {letterSpacing: spacer} : {letterSpacing: '0px'}
            }
            onClick={onClick}
        >
            {icon && 
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
            }
            {label}
        </button>
    )
}

export default SausageButton
