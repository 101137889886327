import React, {useState, useContext, useReducer} from 'react';
import {useTranslate} from 'react-polyglot';
import '@rmwc/badge/badge.css';
import './CustomLoaderSaveChanges.css';
import '@material/textfield/dist/mdc.textfield.css';
import { CustomLoaderContext } from '../../context/CustomLoaderContext';
import AlertDialog from '../general/AlertDialog';
import ShowLoadingContext from '../../context/ShowLoadingContext';
import CustomLoaderService from '../../services/CustomLoader';
import Notification from '../notification/notification';

function CustomLoaderSaveChanges(props) {
    const campaignId = window.location.pathname.split('/')[3];
    const t = useTranslate();
    const { setShowLoading } = useContext(ShowLoadingContext)

    const {
        iconColor,setIconColor, 
        textColor,setTextColor, 
        headerFont, setHeaderFont,
        loadingBarColor,setLoadingBarColor, 
        backgroundColor,setBackgroundColor,
        backgroundGradiantColor,setBackgroundGradiantColor,
        isGrid,setIsGridActive,
        isGradiant,setIsGradiantActive,
        logoFile, setLogoURL,logoURL,
        centerLogoFile, setCenterLogoFile,
        centerLogoLocal, setCenterLogoLocal,
        backgroundFile, setBackgroundFile,
        backgroundLocal, setBackgroundLocal,
        resetBackground, setResetBackground,
        resetCenterLogo, setResetCenterLogo,
        defaultLogo,setDefaultLogo,
        footerLogoThemeColor, setFooterLogoThemeColor,
        changesSaved, setChangesSaved,
        resetLogo, setResetLogo } = useContext(CustomLoaderContext);

    const [{ showNotification, propsNotification }, setNotification] = useReducer(
        (prevState, currState) => 
        ({ ...prevState, ...currState }),
        { showNotification: false, 
        propsNotification: {} 
    });
    const [open,setOpen] = useState({
        title : "",
        subtitle: "",
        isOpen: false
    })

    const save = async() => {
        setShowLoading(true)
        let formData = new FormData();
        formData.append("isUpdate", true);
        formData.append("iconColor", defaultLogo == true ? iconColor : "null");
        formData.append("textColor", textColor);    
        formData.append("headerFont", headerFont);
        formData.append("loadingBarColor", loadingBarColor);
        formData.append("backgroundColor", backgroundColor);
        formData.append("footerColor", '#FFFFFF');
        formData.append("isGrid", isGrid);
        formData.append("isGradiant", isGradiant);
        formData.append("backgroundGradiantColor", backgroundGradiantColor);
        // DEPRECATE>>>>
        formData.append("footerText", '');
        formData.append("footerTextBold", '');
        formData.append("footerTextEn", '');
        formData.append("footerTextBoldEn", '');
        // <<<<DEPRECATE
        formData.append("footerThemeColor", footerLogoThemeColor);
        formData.append("logoFile", logoFile);
        formData.append("centerLogoFile", centerLogoFile);
        formData.append("backgroundFile", backgroundFile);
        formData.append("resetBackground", resetBackground);
        formData.append("resetCenterLogo", resetCenterLogo);
        formData.append("hasCenterLogoURL", centerLogoLocal != '' && centerLogoLocal !== undefined);
        formData.append("hasBackgroundURL", backgroundLocal != '' && backgroundLocal !== undefined);
        formData.append("hasLogoURL", logoFile != '' && logoFile !== undefined);
        formData.append("hasDefaultLogo", defaultLogo);
        let rta = await CustomLoaderService.updateCustomLoaderConfig(campaignId, formData);
        setShowLoading(false)
        if(rta.status == 200){
            setNotification({ showNotification: true, propsNotification: { label: "OK", message:t('customLoaderLayout.applyChanges') } })
            setChangesSaved(true);
        }
        else{
            setNotification({ showNotification: true, propsNotification: { label: "Error", message:t('customLoaderLayout.errorChanges') } })
        }
    }

    const setData = (jsonData) => {
        setIconColor(jsonData.iconColor == "null" ? "#3434E0" : jsonData.iconColor);
        setTextColor(jsonData.textColor);
        setLoadingBarColor(jsonData.loadingBarColor);
        setBackgroundColor(jsonData.backgroundColor);
        setBackgroundGradiantColor(jsonData.backgroundGradiantColor);
        setIsGridActive(jsonData.isGrid == 'true' ? true : false );
        setIsGradiantActive(jsonData.isGradiant == 'true' ? true : false );
        setLogoURL(jsonData.logoURL);
        setCenterLogoLocal(jsonData.centerLogoURL || ""); // to be added
        setBackgroundLocal(jsonData.backgroundURL);
        setResetLogo(false);
        setResetBackground(false);
        setResetCenterLogo(false);
        setShowLoading(false);
        setFooterLogoThemeColor(jsonData.footerThemeColor || 'black');
        setDefaultLogo(jsonData.hasDefaultLogo == "true" || jsonData.hasDefaultLogo == true ? true : false)
        setHeaderFont(jsonData.headerFont || 'CoaFont');
    }
    
    async function discardChanges() {
        setShowLoading(true);
        (CustomLoaderService.applyInitialConfiguration(campaignId))
        .then(response=> response.json())
        .then(data => {
            setChangesSaved(false);
            setData(data);
            setBackgroundFile(undefined)
            setCenterLogoFile(undefined)
        });
    }

    const openDialog = () => {
        setOpen({
            title: t('customLoaderLayout.discard'),
            subtitle: t('customLoaderLayout.discardMessage'),
            isOpen: true
        })
    }
    function closeNotification() {
        setNotification({ showNotification: false });
    }

    return (
        <>
            <div className='buttons-container'>
                <div className='apply-changes-button'>
                    <button disabled={changesSaved} className='save-button' variant='contained' onClick={save}> {t('customLoaderLayout.apply')}</button>
                </div>
                <button className='discard-changes-button' size='small' variant='outlined' onClick={openDialog}>{t('customLoaderLayout.discard')}</button>
            </div>
            <AlertDialog
                title={open.title}
                bodyMessage={open.subtitle}
                setOpen={setOpen}
                open={open.isOpen}
                functionHandler={discardChanges}
                buttonMessage={t("customLoaderLayout.yes")}
            />
            {showNotification && <Notification {...propsNotification} onClose={closeNotification} />}
    </>);
}

export default CustomLoaderSaveChanges;
