import React, {useState, useContext, useEffect} from 'react';
import {Row} from 'react-flexbox-grid';
import {navigate} from "@reach/router"
import {useTranslate} from 'react-polyglot';
import {Button} from '@rmwc/button';
import {Badge} from '@rmwc/badge';

import Modal from '../modal/modal';
import NewExperience from '../actions/NewExperience';
import Notification from '../notification/notification';
import Icon from '../icon';
import NavLink from './navLink';
import CheckPermission from '../checkPermision/checkPermision';
import {userGroupsObject} from '../../utils/userGroups';
import Appcontext from '../app/AppContext';
import {UserContext} from "../../context/UserContext";
import {getExpirationDays} from '../../utils/user';
import amplitude from 'amplitude-js';

import '@rmwc/badge/badge.css';
import './Menu.css';

import SausageButton from "../coa/SausageButton";


const IconsInactiveAssets = {
    campaign: 'folder-inactive',
    recent: 'recent-inactive',
    rocket: 'rocket-inactive',
    reports: 'reports-normal',
    webComponents: 'web-components-inactive',
    panel: 'control-panel-inactive',
    reportsV2: 'reports-inactive',
    samplesGallery: 'gallery-inactive',
    videoTutorials: 'video-tutorials-inactive',    
}


function Menu(props) {
    const [collapse, setCollapse] = useState(false);
    const [exp, setExp] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const context = useContext(Appcontext);
    const {setUserData, setNavLinks} = useContext(UserContext);
    const [icons, setSelectedIcons] = useState({
        campaign: 'folder-hovered',
        recent: 'recent-inactive',
        rocket: 'rocket-inactive',
        reports: 'reports-normal',
        webComponents: 'web-components-inactive',
        panel: 'control-panel-inactive',
        reportsV2: 'reports-inactive',
        samplesGallery: 'gallery-inactive',
        videoTutorials: 'video-tutorials-inactive',
    });
    
    useEffect(() => {
        let paths = window.location.pathname.split('/');
        let hasCampaign = paths.length > 1 && paths[3] === 'campaign';
        let hasUser = paths.length > 1 && paths[1] === 'user';
        let campaignSelfUser = window.location.pathname.split('/')[1] === 'campaign';
        if (campaignSelfUser){
            setSelectedIcons({
                ...IconsInactiveAssets,
                campaign: 'folder-hovered',
            });
        }
        if (hasUser){
            setSelectedIcons({
                ...IconsInactiveAssets,
                campaign: 'folder-hovered',
            });
        }
        if (hasCampaign){
            setSelectedIcons({
                ...IconsInactiveAssets,
                campaign: 'folder-hovered',
            });
        }
        if (window.location.pathname === '/') {
            setSelectedIcons({
                ...IconsInactiveAssets,
                campaign: 'folder-hovered',
            });
        }
        if (window.location.pathname === '/published') {
            setSelectedIcons({
                ...IconsInactiveAssets,
                rocket: 'rocket-hovered',
            });
        }
        if (window.location.pathname === '/controlpanel') {
            setSelectedIcons({
                ...IconsInactiveAssets,
                panel: 'control-panel',
            });
        }
        if (window.location.pathname === '/recent') {
            setSelectedIcons({
                ...IconsInactiveAssets,
                recent: 'recent-hovered',
            });
        }
        if (window.location.pathname === '/reports') {
            setSelectedIcons({
                ...IconsInactiveAssets,
                reportsV2: 'reports-hovered',
            });
        }
        if (window.location.pathname === '/gallery') {
            setSelectedIcons({
                ...IconsInactiveAssets,
                samplesGallery: 'gallery-hovered'
            });
        }
        if (window.location.pathname === '/web-components') {
            setSelectedIcons({
                ...IconsInactiveAssets,
                webComponents: 'web-components',
            });
        }
        if (window.location.pathname === '/video-tutorials') {
            setSelectedIcons({
                ...IconsInactiveAssets,
                videoTutorials: 'video-tutorials-hovered',
            });
        }
    }, []);

    const setUserDataEmpty = () => {
        setSelectedIcons({
            ...icons, ...{
                campaign: 'folder-hovered', recent: 'recent-inactive', rocket: 'rocket-inactive',
                reports: 'reports-normal', panel: 'control-panel',
                samplesGallery: 'gallery-inactive'
            }
        });
        setUserData({
            id: '',
            username: ''
        })
    };

    const t = useTranslate();
    const collapseSidebar = (e) => {
        setCollapse(!collapse);

        props.onCollapse(!collapse);
    };

    function createExperience() {
        amplitude.getInstance().logEvent('Click en Crear Experiencia');
        navigate('/createExp')
    }

    function showSubscription() {
        navigate('/account/subscription');
        context.setOpenSubPro(true)
    }

    const closeHandler = (val) => {
        setExp(false);
        if (val) {
            setShowNotification(true);
        }
    };

    const ButtonFreelance = () => {
        return (
            <>
                {!collapse && (
                    <div style={{
                        width: '144px',
                        height: '32px',
                        marginLeft: '16px',
                        marginRight: '16px',
                        'align': 'center',
                        marginBottom: '8px'
                    }}>
                        <p className={'titleFooterSubs'} style={{textAlign: 'center'}}>
                            {t('sidebar.footer.title')}
                            <span style={{fontWeight: 'bold'}}
                                  className="time"> {`${getExpirationDays(context.userData.created_at)} ${t('sidebar.footer.time')}`}</span>
                        </p>
                    </div>
                )}
                <Button
                    style={{width: `${collapse ? '56px' : '176px'}`, height: `${collapse ? '56px' : '32px'}`}}
                    label={
                        collapse ? (
                            <Icon name="vip-crown" className={'crown-collapsed'}/>
                        ) : (
                            t('sidebar.footer.action')
                        )
                    }
                    className={'center outlined red add-uncollapsed styleText'}
                    onClick={showSubscription}
                />
            </>
        );
    };

    const canSeeReport = () => {
        if (context.userGroups.includes(userGroupsObject.CompanyUsers)) {
            return context.userData.company ? context.userData.company.canSeeReport : false;
        }
        return true;
    };
   
    const isFreelance = () => context.userGroups.includes(userGroupsObject.FreelanceUsers);
    const isFreelancePaid = () => context.userGroups.includes(userGroupsObject.FreelancePaidUsers);

    return (
        <div
            className={collapse ? 'sidebar-container collapsed' : 'sidebar-container'}
        >
            <div className="collapsable-container">
                <Icon
                    name={'caret-left'}
                    className={
                        collapse
                            ? 'sidebar-collapser collapsed'
                            : `sidebar-collapser uncollapsed`
                    }
                    onClickFn={collapseSidebar}
                />
            </div>
            {collapse ? (
                <div className="button-container">
                    <Button style={{width: '72px', height: '40px'}}
                        label={<Icon name={'add'}/>}
                        className={'add-collapsed'}
                        raised
                        onClick={createExperience}
                    />
                </div>
            ) : (
                <div className="button-container">
                    <SausageButton
                        label={t('sidebar.action')}
                        onClick={createExperience}
                        size="medium"
                        fsize="16px"
                        spacer="2px"
                        raised
                        fluid
                    />
                    
                    {/* <Button style={{width: '248px', height: '40px'}}
                            label={t('sidebar.action')}
                            raised
                            className={'add-uncollapsed'}
                    /> */}
                </div>
            )}

            <Row className={'menu-container hrefNoUnder'}>
                <ul className="menu">
                    <NavLink onClick={setUserDataEmpty} to="/">
                        <li className={`menu-item ${icons.campaign === 'folder-hovered' ? 'active colorBlue' : ''}`}>
                            {/*<Icon
                                className={collapse ? 'icon-menu collapsed' : 'icon-menu'}
                            />*/}
                              <div style={{'display': 'flex', alignItems: 'center'}}>
                                <img alt={''} src={`/images/${icons.campaign}.svg`}
                                     style={{
                                         marginLeft: !collapse ? '8px' : '12px',
                                         marginRight: '8px',
                                         width: '16px',
                                         height: '16px'
                                     }}/>
                                {!collapse && <span style={{
                                    marginTop: '12px',
                                    marginBottom: '12px'
                                }}>{t('sidebar.menu.campaign')}</span>}
                            </div>
                        </li>
                    </NavLink>
                    <CheckPermission
                        component={(
                            <NavLink to="/gallery" onClick={() => {
                                setSelectedIcons({
                                    ...icons, ...{
                                        campaign: 'folder-inactive', recent: 'recent-inactive', rocket: 'rocket-hovered',
                                        reports: 'reports-normal', panel: 'control-panel', samplesGallery: 'gallery-hovered'
                                    }
                                });
                                setNavLinks([{
                                    id: '',
                                    name: t('pages.samplesGallery.samplesGallery'),
                                    tab: ''
                                }]);
                            }}>
                                <li className="menu-item">
                                    <div style={{'display': 'flex', alignItems: 'center'}}>
                                        <img alt={''} src={`/images/${icons.samplesGallery}.svg`} style={{
                                            marginLeft: !collapse ? '8px' : '12px',
                                            marginRight: '8px',
                                            width: '16px',
                                            height: '16px'
                                        }}/>
                                        {!collapse && <span>{t('sidebar.menu.samplesGallery')}</span>}
                                    </div>
                                </li>
                            </NavLink>)}
                        groups={[
                            userGroupsObject.CamonappUsers,
                            userGroupsObject.CamonappAdminUsers,
                            userGroupsObject.CamonappSalesUsers,
                            userGroupsObject.CompanyUsers,
                            userGroupsObject.FreelancePaidUsers,
                            userGroupsObject.FreelanceUsers,
                        ]}
                    />
                    <NavLink onClick={() => {
                        setSelectedIcons({
                            ...icons, ...{
                                campaign: 'folder-inactive', recent: 'recent-hovered', rocket: 'rocket-inactive',
                                reports: 'reports-normal',  panel: 'control-panel-inactive', samplesGallery: 'gallery-inactive'
                            }
                        });
                        setNavLinks([{
                            id: '',
                            name: t('pages.recent.recentExp'),
                            tab: 'status'
                        }]);
                    }} to="/recent">
                        <li className="menu-item">
                            {/*<Icon
                                name="recent"
                                className={collapse ? 'icon-menu collapsed' : 'icon-menu'}
                            />*/}
                            <div style={{'display': 'flex', alignItems: 'center'}}>
                                <img alt={''} src={`/images/${icons.recent}.svg`} style={{
                                    marginLeft: !collapse ? '8px' : '12px',
                                    marginRight: '8px',
                                    width: '16px',
                                    height: '16px'
                                }}/>
                                {!collapse && <span style={{
                                    marginTop: '12px',
                                    marginBottom: '12px'
                                }}>{t('sidebar.menu.recent')}</span>}
                            </div>
                        </li>
                    </NavLink>
                    <NavLink to="/published" onClick={() => {
                        setSelectedIcons({
                            ...IconsInactiveAssets,
                            rocket: 'rocket-hovered',
                        });
                        setNavLinks([{
                            id: '',
                            name: t('pages.status.statusExp'),
                            tab: 'status'
                        }]);
                    }}>
                        <li className="menu-item">
                            <div style={{'display': 'flex', alignItems: 'center'}}>
                                <img alt={''} src={`/images/${icons.rocket}.svg`} style={{
                                    marginLeft: !collapse ? '8px' : '12px',
                                    marginRight: '8px',
                                    width: '16px',
                                    height: '16px'
                                }}/>
                                {!collapse && <span>{t('sidebar.menu.published')}</span>}
                            </div>

                        </li>
                    </NavLink>
                    <CheckPermission
                        component={canSeeReport() && (<NavLink to="/controlpanel" onClick={() => {
                            setSelectedIcons({
                                ...IconsInactiveAssets,
                                panel: 'control-panel',
                            });
                            setNavLinks([{
                                id: '',
                                name: t('pages.status.statusExp'),
                                tab: ''
                            }]);
                        }}>
                            <li className="menu-item">
                                <div style={{'display': 'flex', alignItems: 'center'}}>
                                    <img alt={''} src={`/images/${icons.panel}.svg`} style={{
                                        marginLeft: !collapse ? '8px' : '12px',
                                        marginRight: '8px',
                                        width: '16px',
                                        height: '16px'
                                    }}/>
                                    {!collapse && <span>{t('sidebar.menu.controlPanel')}</span>}
                                </div>
    
                            </li>
                        </NavLink>)}
                         groups={[
                            userGroupsObject.CamonappAdminUsers,
                            userGroupsObject.CamonappUsers
                        ]}
                    />
                     <CheckPermission
                        component={(
                            <NavLink to="/reports" onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                window.location.replace(process.env.REACT_APP_REPORTS_V2)
                            }}>
                                <li className="menu-item report">
                                    <div className={`label-report`}>
                                        <div style={{'display': 'flex', alignItems: 'center'}}>
                                            <img alt={''} src={`/images/${icons.reports}.svg`} style={{
                                                marginLeft: !collapse ? '8px' : '12px',
                                                marginRight: '8px',
                                                width: '16px',
                                                height: '16px'
                                            }}/>
                                            {!collapse && <span style={{
                                                marginTop: '12px',
                                                marginBottom: '12px'
                                            }}>{t('sidebar.menu.reportsV2')}</span>}
                                        </div>
                                    </div>
                                </li>
                            </NavLink>
                        )}
                        groups={[
                            userGroupsObject.CamonappUsers,
                            userGroupsObject.CamonappAdminUsers,
                            userGroupsObject.CamonappSalesUsers,
                            userGroupsObject.FreelancePaidUsers,
                            userGroupsObject.CompanyUsers,
                            userGroupsObject.FreelanceUsers
                        ]}
                    /> 
                    <CheckPermission
                        component={
                            <NavLink onClick={() => {
                                setSelectedIcons({
                                    ...icons, ...{
                                        campaign: 'folder-inactive', recent: 'recent-hovered', rocket: 'rocket-inactive',
                                        reports: 'reports-inanctive',webComponents: 'web-components', samplesGallery: 'gallery-inactive'
                                    }
                                });
                                setNavLinks([{
                                    id: '',
                                    name: t('pages.webComponents.webComponents'),
                                    tab: 'web-components'
                                }]);
                            }} to="/web-components">
                                <li className="menu-item">
                                    {/*<Icon
                                        name="recent"
                                        className={collapse ? 'icon-menu collapsed' : 'icon-menu'}
                                    />*/}
                                    <div style={{'display': 'flex', alignItems: 'center'}}>
                                        <img alt={''} src={`/images/${icons.webComponents}.svg`} style={{
                                            marginLeft: !collapse ? '8px' : '12px',
                                            marginRight: '8px',
                                            width: '16px',
                                            height: '16px'
                                        }}/>
                                        {!collapse && <span style={{
                                            marginTop: '12px',
                                            marginBottom: '12px'
                                        }}>{t('sidebar.menu.webComponents')}</span>}
                                    </div>
                                </li>
                            </NavLink>
                        }
                        groups={[
                            userGroupsObject.CamonappAdminUsers,
                            userGroupsObject.CamonappSalesUsers,
                            userGroupsObject.CamonappUsers
                        ]}
                    />
                    <NavLink to="/video-tutorials" onClick={() => {
                        setNavLinks([{
                            id: '',
                            name: 'Videos',
                            tab: 'status'
                        }]);
                        setSelectedIcons({
                            ...IconsInactiveAssets,
                            videoTutorials: 'video-tutorials-hovered'
                        });
                    }}>
                        <li className="menu-item">
                            <div style={{'display': 'flex', alignItems: 'center'}}>
                                <img alt={''} src={`/images/${icons.videoTutorials}.svg`} style={{
                                    marginLeft: !collapse ? '8px' : '12px',
                                    marginRight: '8px',
                                    width: '16px',
                                    height: '16px'
                                }}/>
                                {!collapse && <span>{t('sidebar.menu.videoTutorials')} <Badge theme={'primaryBg'} align="inline" label="New" /></span>}
                            </div>

                        </li>
                    </NavLink>
                </ul>
            </Row>
            <div className={'sidebar-footer'}>
                <div style={{
                    width: '176px',
                    height: '72px',
                    marginLeft: `${collapse ? '8px' : '36px'}`,
                    marginRight: `${collapse ? '8px' : '36px'}`
                }}>
                    <CheckPermission component={<ButtonFreelance/>} groups={[userGroupsObject.FreelanceUsers]}/>
                </div>
            </div>
            {exp && (
                <Modal
                    component={NewExperience}
                    showback
                    buttonLess
                    full
                    onClose={closeHandler}
                />
            )}
            {showNotification && (
                <Notification
                    message={t('notification.actions.expcreated')}
                    label={t('notification.label.ok')}
                />
            )}
        </div>
    );
}

export default Menu;
