import React, {createContext, useState} from 'react';
import getLocale from '../utils/locale';

export const CustomLoaderContext = createContext();

const CustomLoaderProvider = (props) => {

    const [iconColor, setIconColor] = useState("#3434E0"); 
    const [textColor, setTextColor] = useState("#3434E0"); 
    const [headerFont, setHeaderFont] = useState("CoaFont");
    const [loadingBarColor, setLoadingBarColor] = useState("#3434E0"); 
    const [backgroundColor, setBackgroundColor] = useState("#ffffff"); 
    const [isGrid, setIsGridActive] = useState(true); 
    const [isGradiant, setIsGradiantActive] = useState(false);
    const [backgroundGradiantColor, setBackgroundGradiantColor] = useState("#ffffff"); 
    const [footerLogoThemeColor, setFooterLogoThemeColor] = useState("black");
    const [logoFile, setLogoFile] = useState("undefined");

    const [language, setLanguage] = useState(getLocale());
    const [changesSaved, setChangesSaved] = useState(true)

    const [centerLogoFile, setCenterLogoFile] = useState(); 
    const [centerLogoLocal, setCenterLogoLocal] = useState(); // "undefined"
    const [centerLogoURL, setCenterLogoURL] = useState("");

    const [backgroundFile, setBackgroundFile] = useState(); 
    const [backgroundLocal, setBackgroundLocal] = useState("undefined"); 
    const [backgroundURL, setBackgroundURL] = useState("/images/icon_360.svg");

    const [logoURL, setLogoURL] = useState("");
    const [resetLogo, setResetLogo] = useState(false);
    const [resetBackground, setResetBackground] = useState(false);
    const [resetCenterLogo, setResetCenterLogo] = useState(false);
    const [defaultLogo, setDefaultLogo] = useState(null)

    return (<CustomLoaderContext.Provider
        value={{
                iconColor, setIconColor,
                textColor, setTextColor,
                headerFont, setHeaderFont,
                language, setLanguage,
                changesSaved, setChangesSaved,
                loadingBarColor, setLoadingBarColor,
                backgroundColor, setBackgroundColor,
                isGrid, setIsGridActive,
                isGradiant, setIsGradiantActive,
                backgroundGradiantColor, setBackgroundGradiantColor,
                logoFile, setLogoFile,
                defaultLogo,setDefaultLogo,
                centerLogoFile, setCenterLogoFile,
                centerLogoLocal, setCenterLogoLocal,
                centerLogoURL, setCenterLogoURL,
                backgroundFile, setBackgroundFile,
                backgroundLocal,setBackgroundLocal,
                backgroundURL, setBackgroundURL,
                logoURL, setLogoURL,
                resetLogo, setResetLogo,
                resetBackground, setResetBackground,
                resetCenterLogo, setResetCenterLogo,
                footerLogoThemeColor, setFooterLogoThemeColor,
                }}>
            {props.children}
        </CustomLoaderContext.Provider>
    );
};

export default CustomLoaderProvider;
