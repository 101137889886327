import React, {useState, useContext, useEffect} from 'react';
import {useTranslate} from 'react-polyglot';
import '@rmwc/badge/badge.css';
import './CustomLoaderMenu.css';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ColorPicker from '../colorPicker/ColorPicker';
import InputFileDragAndDrop from '../inputFileDragAndDrop/InputFileDragAndDrop'
import '@material/textfield/dist/mdc.textfield.css';
import { CustomLoaderContext } from '../../context/CustomLoaderContext';
import {
    FontSizeOutlined,
    FolderOutlined,
    ClockCircleOutlined,
    DownloadOutlined,
    PictureOutlined
  } from '@ant-design/icons';
import AlertDialog from '../general/AlertDialog';
import { Checkbox, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { AVAILABLE_FONTS, getFontFamily } from './availableFonts';

function CustomLoaderMenu(props) {

    const t = useTranslate();
    const [open,setOpen] = useState({
        title: '',
        subtitle: '',
        isOpen: false
    })
    const {iconColor, setIconColor, 
           textColor, setTextColor, 
           headerFont, setHeaderFont,
           setChangesSaved,
           loadingBarColor, setLoadingBarColor, 
           backgroundColor, setBackgroundColor,
           isGrid, setIsGridActive,
           isGradiant, setIsGradiantActive,
           backgroundGradiantColor, setBackgroundGradiantColor,
           logoFile, setLogoFile,
           setResetLogo,
           defaultLogo,setDefaultLogo,
           centerLogoFile, setCenterLogoFile,
           setResetCenterLogo,
           centerLogoLocal, setCenterLogoLocal,
           centerLogoURL, setCenterLogoURL,
           backgroundFile, setBackgroundFile,
           setResetBackground,
           backgroundLocal,setBackgroundLocal,
           backgroundURL, setBackgroundURL,
           logoURL, setLogoURL,
           footerLogoThemeColor, setFooterLogoThemeColor
        } = useContext(CustomLoaderContext);

    const [icon, setIconOpen] = useState(false);
    const [text, setTextOpen] = useState(false);
    const [logo, setLogoOpen] = useState(false);
    // const [centerLogo, setCenterLogoOpen] = useState(false);
    const [loadingBar, setLoadingBarOpen] = useState(false);
    const [footer, setFooterOpen] = useState(false);
    const [centerLogo, setCenterLogoOpen] = useState(false);
    const [background, setBackgroundOpen] = useState(false);
    const [collapse, setCollapse] = useState(false);

    const handleChangeHeaderFont = (event) => {
        setChangesSaved(false);
        setHeaderFont(event.target.value);
    }

    // footer logo
    const flThemeColors = ["black", "white"];

    const closeAll = () => {
        setIconOpen(false);
        setTextOpen(false);
        setLogoOpen(false);
        setLoadingBarOpen(false);
        setFooterOpen(false);
        setBackgroundOpen(false);
    }
    
    const handleIconClick = () => {
        var status = icon;
        closeAll();
        setIconOpen(!status);
    };

    const handleTextClick = () => {
        var status = text;
        closeAll();
        setTextOpen(!status);
    };

    const handleCenterLogoClick = () => {
        var status = centerLogo;
        closeAll();
        setCenterLogoOpen(!status);        
    }

    const handleLoadingBarClick = () => {
        var status = loadingBar;
        closeAll();
        setLoadingBarOpen(!status);
    };

    const handleBackgroundClick = () => {
        var status = background;
        closeAll();
        setBackgroundOpen(!status);
    };

    const handleLogoClick = () => {
        var status = logo;
        closeAll();
        setLogoOpen(!status);
    };

    const handleFooterClick = () => {
        var status = footer;
        closeAll();
        setFooterOpen(!status);
    };

    const handleIconColor = (color) => {
        setChangesSaved(false);
        setIconColor(color);
    }

    const handleTextColor = (color) => {
        setChangesSaved(false);
        setTextColor(color);
    }

    const handleLoadingBarColor = (color) => {
        setChangesSaved(false);
        setLoadingBarColor(color);
    }

    const handleBackgroundColor = (color) => {
        setChangesSaved(false);
        setBackgroundColor(color);
    }

    const handleGrid = () => {
        setChangesSaved(false);
        setIsGridActive(!isGrid);
    }

    const handleGradiant = () => {
        setChangesSaved(false);
        setIsGradiantActive(!isGradiant);
    }

    const handleGradiantBackgroundColor = (color) => {
        setChangesSaved(false);
        setBackgroundGradiantColor(color);
    }

    const handleBackgroundFile = (file) => {
        setChangesSaved(false);
        setBackgroundFile(file);
    }

    const handleLogoFile = (file) => {
        setChangesSaved(false);
        setLogoFile(file);
    }

    const handleCenterLogoFile = (file) => {
        setChangesSaved(false);
        setCenterLogoFile(file)
    }

    const handleChangeFooterThemeColor = (event) => {
        setChangesSaved(false);
        setFooterLogoThemeColor(event.target.value);
    };

    const handleBackgroundURL = (url) => {
        setBackgroundURL(url);
    }

    const handleLogoURL = (url) => {
        setLogoURL(url);
    }

    const handleCenterLogoURL = (url) => {
        setCenterLogoURL(url);
    }

    const handleAlert = () => {
        console.log(" ")
    }

    useEffect(() => {
        // Create a promise for each font
        const fontPromises = AVAILABLE_FONTS.flatMap(fontFamily => {
            return fontFamily.urls.map(url => new FontFace(fontFamily.name, `url(${url})`).load());
        });

        // Wait for all fonts to load
        Promise.all(fontPromises)
            .then(fonts => {
                fonts.forEach(font => {
                    document.fonts.add(font);
                });
            })
            .catch(error => {
                console.error('Error loading fonts:', error);
            });

    },[])

    useEffect(() => {
        if(logoURL){
            setIconColor("#3434E0")
        }
    },[logoURL])

    useEffect(() => {
      if(defaultLogo){
        setLogoFile('');
        setLogoURL('');
      }
    },[defaultLogo])

    useEffect(() => {
        if (backgroundFile) {
            setIsGradiantActive(false);
            setIsGridActive(false);
        }
    }, [backgroundFile])

    return (
        <div className={collapse ? 'sidebar-container collapsed' : 'sidebar-container custom-loader-sidebar'}>
            
            <List>                
                <ListItem button onClick={handleLogoClick} className={logo ? "item-open menu-item-custom" : "item-close menu-item-custom"}>
                    <FolderOutlined src={`/images/reports-hovered.svg`} style={{
                                                marginLeft: '8px',
                                                marginRight: '8px',
                                                width: '16px',
                                                height: '16px'
                                            }}/>
                    <ListItemText primary={t('customLoaderSidebar.menu.header')} />
                </ListItem>
                <Collapse in={logo} unmountOnExit>
                    <ListItem disabled={!defaultLogo}>
                        <ColorPicker disabled={!defaultLogo} defaultColor={iconColor} handleColor={(color)=>handleIconColor(color)}></ColorPicker>
                    </ListItem>
                    <ListItem>
                        <InputFileDragAndDrop
                            id="logoFile"
                            acceptedExtensions={".svg, .jpg, .jpeg, .png"}
                            setOpen={setOpen}
                            handleURL={(url)=>handleLogoURL(url)}
                            handleFile={(file)=>handleLogoFile(file)}
                            defaultFile={defaultLogo ? "" : logoURL}
                            onFileLoaded={(filePath) => {
                                setLogoURL(filePath);
                                setLogoFile((file)=>handleLogoFile(file));
                                setResetLogo(false);
                                setDefaultLogo(false)
                            }}
                            onQuitFile={() => {
                                setLogoURL('');
                                setLogoFile('');
                                setResetLogo(true);
                                setDefaultLogo(true)
                            }}
                        ></InputFileDragAndDrop>
                    </ListItem>
                </Collapse>

                <ListItem button onClick={handleTextClick} className={text ? "item-open menu-item-custom" : "item-close menu-item-custom"}>
                    <FontSizeOutlined style={{
                                                marginLeft: '8px',
                                                marginRight: '8px',
                                                width: '16px',
                                                height: '16px'
                                            }} />
                    <ListItemText primary={t('customLoaderSidebar.menu.headerText')} />
                </ListItem>
                <Collapse in={text}>
                     <ListItem>
                        <ColorPicker defaultColor={textColor} handleColor={(color)=>handleTextColor(color)}></ColorPicker>
                    </ListItem>
                    <ListItem className="text-field">
                        <select
                            className="select-header-font"
                            value={headerFont}
                            style={{
                                fontFamily: getFontFamily(headerFont),
                                fontWeight: 'normal'
                            }}
                            onChange={handleChangeHeaderFont}
                            label=" "
                        >
                            {
                                AVAILABLE_FONTS.map((font) => {
                                    return (
                                        <option 
                                            key={`font-${font.name}`} 
                                            value={font.name}
                                            style={{ fontFamily: font.normal.fontFamily, fontWeight: font.normal.fontWeight, fontSize: '16px', padding: '8px' }}
                                        >
                                            {font.name}
                                        </option>
                                    )
                                })
                            }
                        </select>
                    </ListItem>
                </Collapse>

                <ListItem button onClick={handleCenterLogoClick} className={centerLogo ? "item-open menu-item-custom" : "item-close menu-item-custom"}>
                    <PictureOutlined src={`/images/reports-hovered.svg`} style={{
                                                marginLeft: '8px',
                                                marginRight: '8px',
                                                width: '16px',
                                                height: '16px'
                                            }}/>
                    <ListItemText primary={t('customLoaderSidebar.menu.centerLogo')} />
                </ListItem>
                <Collapse in={centerLogo} unmountOnExit>
                    <ListItem>
                        <InputFileDragAndDrop
                            id="centerLogoFile"
                            acceptedExtensions={".svg, .jpg, .jpeg, .png"}
                            setOpen={setOpen}
                            handleURL={(url)=>handleCenterLogoURL(url)}
                            handleFile={(file)=>handleCenterLogoFile(file)}
                            defaultFile={centerLogoLocal}
                            onFileLoaded={(filePath) => {
                                setCenterLogoLocal(filePath);
                                setCenterLogoFile((file)=>handleCenterLogoFile(file));
                                setResetCenterLogo(false);
                            }}
                            onQuitFile={() => {
                                setCenterLogoFile();
                                setCenterLogoLocal('');
                                setResetCenterLogo(true);
                            }}
                        ></InputFileDragAndDrop>
                    </ListItem>
                </Collapse>

                <ListItem button onClick={handleLoadingBarClick} className={loadingBar ? "item-open menu-item-custom" : "item-close menu-item-custom"}>
                    <ClockCircleOutlined style={{
                                                marginLeft: '8px',
                                                marginRight: '8px',
                                                width: '16px',
                                                height: '16px'
                                            }}/>
                    <ListItemText primary={t('customLoaderSidebar.menu.loadingBar')} />
                </ListItem>
                <Collapse in={loadingBar}>
                    <ListItem>
                        <ColorPicker defaultColor={loadingBarColor} handleColor={(color)=>handleLoadingBarColor(color)}></ColorPicker>
                    </ListItem>
                </Collapse>

                <ListItem button onClick={handleFooterClick} className={footer ? "item-open menu-item-custom" : "item-close menu-item-custom"}>
                    <DownloadOutlined style={{
                                                marginLeft: '8px',
                                                marginRight: '8px',
                                                width: '16px',
                                                height: '16px'
                                            }}/>
                    <ListItemText primary={t('customLoaderSidebar.menu.footer')} />
                </ListItem>
                <Collapse in={footer} className='footer-container'>
                    <ListItem className="text-field">
                        <select
                            className="select-footer-theme-color"
                            value={footerLogoThemeColor}
                            onChange={handleChangeFooterThemeColor}
                            label=" "
                           
                        >
                            {
                                flThemeColors.map((l) => {
                                    return <option key={`theme-${l}`} value={l}>{t(`customLoaderSidebar.menu.${l}`)}</option>
                                    
                                })
                            }
                        </select>
                    </ListItem>
                </Collapse>

                <ListItem button onClick={handleBackgroundClick} className={background ? "item-open menu-item-custom" : "item-close menu-item-custom"}>
                    <PictureOutlined src={`/images/reports-hovered.svg`} style={{
                                                marginLeft: '8px',
                                                marginRight: '8px',
                                                width: '16px',
                                                height: '16px'
                                            }}/>
                    <ListItemText primary={t('customLoaderSidebar.menu.background')} />
                </ListItem>
                <Collapse in={background} unmountOnExit>
                    <ListItem>
                        <div className='background-item-container'>
                            <ColorPicker disabled={!!backgroundFile} defaultColor={backgroundColor} handleColor={(color)=>handleBackgroundColor(color)}></ColorPicker>

                            {isGradiant && <ColorPicker disabled={!!backgroundFile} defaultColor={backgroundGradiantColor} handleColor={(color)=>handleGradiantBackgroundColor(color)}></ColorPicker>}

                            <div>
                                <Checkbox disabled={!!backgroundFile} checked={isGradiant} onChange={handleGradiant}/> {t('customLoaderSidebar.menu.gradiant')}
                            </div>
                            <div>
                                <Checkbox disabled={!!backgroundFile} checked={isGrid} onChange={handleGrid}/>  {t('customLoaderSidebar.menu.grid')}                            
                            </div>
                            <InputFileDragAndDrop
                                id="backgroundFile"
                                acceptedExtensions={".svg, .jpg, .jpeg, .png"}
                                setOpen={setOpen}
                                handleURL={(url)=>handleBackgroundURL(url)}
                                handleFile={(file)=>handleBackgroundFile(file)}
                                defaultFile={backgroundLocal}
                                onFileLoaded={(filePath) => {
                                    setBackgroundLocal(filePath);
                                    setBackgroundFile((file)=>handleBackgroundFile(file));
                                    setResetBackground(false);
                                    setIsGridActive(false)
                                }}
                                onQuitFile={() => {
                                    setBackgroundFile();
                                    setBackgroundLocal('');
                                    setResetBackground(true);
                                    setIsGridActive(true)
                                }}
                            ></InputFileDragAndDrop>
                        </div>
                    </ListItem>
                </Collapse>
            </List>    
            <AlertDialog
                title={open.title}
                bodyMessage={open.subtitle}
                setOpen={setOpen}
                open={open.isOpen}
                functionHandler={handleAlert}
                buttonMessage='OK'
            />     
        </div>
    );
}

export default CustomLoaderMenu;
