import React, { memo } from 'react'
import { useTranslate } from 'react-polyglot';

import './WarningScreenMobile.scss';

const WarningScreenMobile = memo(function WarningScreenMobile(props) {
    const t = useTranslate();
    return (
        <section className="warning-not-mobile-container">
            <div className="logo-container">
                <img alt="logo" src="logo_studio.svg"></img>
            </div>
            <div className="warning-message-container">
                <h3>{t('pages.warningMobile')}</h3>
            </div>

        </section>
    )
})

WarningScreenMobile.propTypes = {

}

export default WarningScreenMobile
