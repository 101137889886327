import React from 'react';
import {Router} from "@reach/router";
import {useTranslate} from 'react-polyglot';
import Home from '../../pages/Home';
import Status from '../../pages/Status/Status';
import Reports from '../../pages/Reports/Reports';
import Campaign from '../../pages/Campaign';
import Profile from '../../pages/Profile';
import Search from '../../pages/Search';
import Error from '../../pages/Error/Error';
import Confirm from '../../pages/Confirm/Confirm';
import ChangePassword from '../../pages/Confirm/ChangePassword';
import Onboarding from '../../pages/onBoarding/Onboarding';
import Confirmation from '../../pages/Confirmation';
import Recent from '../../pages/Recent/Recent';
import Gallery from '../../pages/Gallery/Gallery';
import Publications from '../../pages/Publications/Publications';
import Layout from '../layout';
import FullPageLayout from '../layout/FullPageLayout';
import LayoutBaseEmpty from '../layout/LayoutBase';
import AppRoute from './AppRoute';
import ExperiencePage from "../../pages/Experience/ExperiencePage";
import ExperiencesListWithStatus from "../Experience/ExperiencesListWithStatus";
import Logout from "../../pages/Home/Logout";
import HomeUserAndCampaigns from "../../pages/Home/HomeUserAndCampaigns";
import WebComponent from '../../pages/WebComponent/WebComponent';
import OverlaysLayout from '../OverlaysLayout/OverlayLayout.jsx'
import CustomLoaderLayout from '../customLoaderLayout/CustomLoaderLayout';
import CustomLoaderEditor from '../../pages/CustomLoader/CustomLoaderEditor';
import ReportsV2 from '../../pages/ReportsV2/ReportsV2';
import VideoTutorials from '../../pages/VideoTutorials/VideoTutorials.js';
import { isMobile } from '../../utils/utils.js';
import WarningScreenMobile from '../../pages/WarningScreenMobile/WarningScreenMobile.js';


function RouterCmp({authState}) {
    const t = useTranslate();
    if (authState === "signedIn") {
        return (

            <Router>
                <AppRoute default path="/" Component={Error} Layout={FullPageLayout}/>
                <AppRoute path="/" Component={Home} Layout={Layout}/>
                <AppRoute path="/campaign/:id" Component={Campaign} Layout={Layout}/>
                <AppRoute path="/user/:id" Component={HomeUserAndCampaigns} Layout={Layout}/>
                <AppRoute path="/user/:id/search" Component={Search} Layout={Layout}/>
                <AppRoute path="/user/:id/project/:idc" Component={HomeUserAndCampaigns} Layout={Layout}/>
                <AppRoute path="/experiences/:type" Layout={Layout} Component={props => <ExperiencesListWithStatus {...props} />}/>
                <AppRoute path="/recent" Component={props => <Recent {...props} />} Layout={Layout}/>
                <AppRoute path="/gallery" Component={props => <Gallery {...props} />} Layout={Layout}/>
                <AppRoute path="/published" Component={props => <Status {...props} />} Layout={Layout}/>
                <AppRoute path="/createExp" Component={props => <ExperiencePage {...props} />}/>
                <AppRoute path="/web-components" Component={props => <WebComponent {...props} />} Layout={OverlaysLayout}/>
                <AppRoute path="account/:tabId" Component={Profile} Layout={LayoutBaseEmpty}/>
                <AppRoute path="/account" Component={Profile} Layout={LayoutBaseEmpty}/>
                <AppRoute path="/search" Component={Search} Layout={Layout}/>
                <AppRoute path="/onboarding" Component={Onboarding} Layout={FullPageLayout}/>
                <AppRoute path="/reports" Component={Reports} Layout={Layout}/>
                <AppRoute path="/reportsV2" Component={ReportsV2} Layout={Layout}/>
                <AppRoute path="/logout" Component={Logout} Layout={null}/>
                <AppRoute path="/customLoader/campaign/:Id" Component={CustomLoaderEditor} Layout={CustomLoaderLayout}/>
                <AppRoute path="/controlpanel" Component={props => <Publications {...props} />} Layout={Layout}/>
                <AppRoute path="/video-tutorials" Component={props => <VideoTutorials {...props} />} Layout={Layout}/>
            </Router>

        )
    } else {
        return (
            <div>
                <Router>
                    <Confirmation path="/confirmationSend" {...{
                        title: t('pages.confirm.confirmationSend.mailConfirmSent'),
                        p: t('pages.confirm.confirmationSend.checkMail'),
                        button: t('pages.confirm.goToLogin'),
                        action: () => {
                            window.location.href = '/'
                        }
                    }} />
                    <Confirmation path="/resetSend" {...{
                        title: t('pages.confirm.resetSend.mailSent'),
                        p: t('pages.confirm.resetSend.checkMail'),
                        button: t('pages.confirm.goToLogin'),
                        action: () => {
                            window.location.href = '/'
                        }
                    }} />
                    <Confirmation path="/resetOk" {...{
                        title: t('pages.confirm.resetOk.passReset'),
                        p: '',
                        button: t('pages.confirm.goToLogin'),
                        action: () => {
                            window.location.href = '/'
                        }
                    }} />
                </Router>

                <Confirm path="/confirm?code=:code&client=:client&username=:username"/>
            </div>

        )
    }
}

export default RouterCmp;
